// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
Rails.start()

import Turbolinks from "turbolinks"
Turbolinks.start()

import "./channels"

import "./jquery"

import 'bootstrap/dist/js/bootstrap'
//import "@fortawesome/fontawesome-free/css/all"
import "../assets/fontawesome/all"
import "chartkick/chart.js"
import "chartkick/highcharts"
import ahoy from "ahoy.js"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

window.ahoy = ahoy;

document.addEventListener("turbolinks:load", function () {
  if (window.posthog) {
    if (window.debugPosthog) { console.log("Capturing Turbolinks Posthog Pageview") }
    window.posthog.capture('$pageview');
  }
  // if (window.freshpaint) {
  //   if (window.debugFreshpaint) { console.log("Capturing Turbolinks Freshpaint Pageview") }
  //   window.freshpaint.page();
  // }
});

document.addEventListener("turbolinks:before-cache", function () {
  window.freshpaint.eventsTracked = false
  window.posthog.eventsTracked = false
  window.ahoy.eventsTracked = false
})
